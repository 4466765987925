import './ParticipantsPage.scss';
import React, {useEffect, useState} from "react";
import {Menu, MenuItem} from "@szhsin/react-menu";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import ConfirmDialog from "../components/ConfirmDialog";
import {
  listParticipants,
  LivekitParticipant,
  removeParticipant,
  updateParticipant
} from "../services/videoManagementService";
import Loading from "../components/Loading";

const DotsIcon = require('../assets/Dots.png');

export interface ParticipantsPageProps {
  roomName: string;
  accessToken: string | undefined;
  localParticipantIdentity: string;
  onClose: () => void;
}

export function ParticipantsPage(props: ParticipantsPageProps) {
  const [showLoading, setShowLoading] = useState(false);
  const [participants, setParticipants] = useState<LivekitParticipant[]>([]);
  const [participantForAccept, setParticipantForAccept] = useState<LivekitParticipant | null>(null);
  const [participantForRemoving, setParticipantForRemoving] = useState<LivekitParticipant | null>(null);
  const [participantInfoDialogContent, setParticipantInfoDialogContent] = useState<React.ReactElement>();

  useEffect(() => {
    getParticipants();
  }, []);

  const getParticipants = () => {
    setShowLoading(true);
    listParticipants(props.roomName, props.accessToken).then((list: LivekitParticipant[]) => {
      if (list && list.length) {
        setParticipants([...list]);
      } else {
        setParticipants([]);
      }
    }).finally(() => {setShowLoading(false);})
  }

  const showParticipantInfo = (participant: LivekitParticipant) => {
    setParticipantInfoDialogContent(
      <div>
        <div><b>Name</b>: {participant.username}</div>
        <div><b>Sid</b>: {participant.sid}</div>
        <div><b>Identity</b>: {participant.identity}</div>
      </div>
    );
  };

  const acceptParticipant = (participant: LivekitParticipant | null) => {
    if (participant) {
      setShowLoading(true);
      updateParticipant(
        {
          username: participant.username,
          identity: participant.identity,
          roomName: props.roomName,
          permissions: {
            canPublish: true,
            canSubscribe: true,
            hidden: false
          }
        },
        props.accessToken
      ).then((success: boolean) => {
        if (success) {
          getParticipants();
        } else {
          setParticipantInfoDialogContent(<div>Server error.</div>)
        }
      }).finally(() => {setShowLoading(false); setParticipantForAccept(null);})
    }
  };

  const removeParticipantAction = (participant: LivekitParticipant | null) => {
    setShowLoading(true);
    removeParticipant(props.roomName, participant?.identity || '', participant?.username || '', props.accessToken).then((success: boolean) => {
      if (success) {
        getParticipants();
      } else {
        setParticipantInfoDialogContent(<div>Server error.</div>)
      }
    }).finally(() => {setShowLoading(false); setParticipantForRemoving(null); })
  };

  const printParticipantMenu = (participant: LivekitParticipant) => {
    return (
      <Menu menuClassName='custom-menu' menuButton={<img className="participants-block-row-icon" src={DotsIcon} alt={'Dots'} />} transition>
        <MenuItem className='custom-menuitem' onClick={() => showParticipantInfo(participant)}>Info</MenuItem>
        {props.localParticipantIdentity !== participant.identity && (
          <MenuItem className='custom-menuitem' onClick={() => setParticipantForRemoving(participant)}>Remove from Call</MenuItem>
        )}
      </Menu>
    );
  }

  const printWaitingParticipant = (participant: LivekitParticipant) => {
    if (!participant) return null;
    return (
      <div className='participants-block-row' key={participant.sid}>
        <div className='participants-block-row-name'>{participant.username}</div>
        <div>
          <button className='lk-disconnect-button participants-block-row-button lk-participant-tile lk-blue-button'
                  onClick={() => setParticipantForAccept(participant)}>Accept</button>
          {printParticipantMenu(participant)}
        </div>
      </div>
    )
  };

  const printInCallParticipant = (participant: LivekitParticipant) => {
    if (!participant) return null;
    return (
      <div className='participants-block-row' key={participant.sid}>
        <div className='participants-block-row-name'>{participant.username}</div>
        {printParticipantMenu(participant)}
      </div>
    );
  };

  return (
    <>
      {showLoading && <Loading />}
      <div id='ParticipantsPage'>
        <div className='participants-header'>
          <span>Participants</span>
          <button className="lk-close-button lk-button lk-chat-toggle" aria-pressed="true" data-lk-unread-msgs="0" onClick={() => props.onClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
              <path fill="#FFF"
                    d="M4.99 3.99a1 1 0 0 0-.697 1.717L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 1 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0-.727-1.717 1 1 0 0 0-.687.303L12 10.586 5.707 4.293a1 1 0 0 0-.717-.303z"></path>
            </svg>
          </button>
        </div>
        <div className='participants-list'>
          <div className='participants-block'>
            <div className='participants-block-title'>Waiting to be admitted</div>
            {participants.filter(i => i.hidden).map(participant => printWaitingParticipant(participant))}
          </div>

          <div className='participants-block'>
            <div className='participants-block-title'>In call</div>
            {participants.filter(i => !i.hidden).map(participant => printInCallParticipant(participant))}
          </div>
        </div>
        {participantForAccept ? (
          <ConfirmDialog dialogType={'confirm'} title={'Accept ' + participantForAccept.username + '?'}
                         onAccept={() => acceptParticipant(participantForAccept)}
                         onReject={() => setParticipantForAccept(null)} />
        ) : null}
        {participantForRemoving ? (
          <ConfirmDialog dialogType={'confirm'} title={'Remove ' + participantForRemoving.username + ' from Call?'}
                         onAccept={() => removeParticipantAction(participantForRemoving)}
                         onReject={() => setParticipantForRemoving(null)} />
        ) : null}
        {participantInfoDialogContent ? (
          <ConfirmDialog dialogType={'info'} title={'Participant info'}
                         onAccept={() => setParticipantInfoDialogContent(undefined)} >
            {participantInfoDialogContent || <></>}
          </ConfirmDialog>
        ) : null}
      </div>
    </>
  );
}

export default ParticipantsPage;
