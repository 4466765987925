import React from "react";
import './DummyLocalParticipant.scss';
import {LeaveIcon, ParticipantPlaceholder, useConnectionState} from "@livekit/components-react";
import {Room, ConnectionState} from "livekit-client";
import {redirectToUrl} from "../utils/utils";

const DummyLocalParticipant = (props: {room: Room}) => {

  const connectionState = useConnectionState(props.room)

  const disconnect = async () => {
    await props.room.disconnect();
    redirectToUrl('/end/' + props.room.name);
  }

  return (
    <div id="DummyLocalParticipant">
      <div className="lk-video-conference">
        <div className="lk-video-conference-inner">
          <div className="lk-grid-layout-wrapper">
            <div data-lk-pagination="false" className="lk-grid-layout">
              <div className="lk-participant-tile">
                <div className="lk-participant-placeholder">
                  <ParticipantPlaceholder />
                </div>
                <div className="lk-participant-metadata">
                  <div className="lk-participant-metadata-item">
                    <span className="lk-participant-name">{props.room.localParticipant.name || ''}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lk-control-bar">
            <button className="lk-disconnect-button" onClick={disconnect}
              disabled={connectionState === ConnectionState.Disconnected}>
              <LeaveIcon/> Leave
            </button>
          </div>
        </div>
      </div>
      {connectionState === ConnectionState.Connecting ? (
        <div className="lk-toast-connection-state lk-toast" style={{top: '0.75rem', padding: '0.75rem 1.25rem', fontSize: '16px'}}>
            <span>Connecting...</span>
        </div>
      ) : (
        <div className="lk-toast-connection-state lk-toast">
          {connectionState === ConnectionState.Disconnected ? (
            <span>Disconnected</span>
          ) : (
            <span className="lk-toast-infoText">Wait until someone allows you to join he call.</span>
          )}
        </div>
      )}
    </div>
  );
};

export default DummyLocalParticipant;
