import '@livekit/components-styles';
import './VideoSpacePage.scss';

import { LiveKitRoom, MediaDeviceMenu, LeaveIcon } from '@livekit/components-react';
import { useEffect, useState } from 'react';
import { RemoteParticipant, Room, RoomEvent } from 'livekit-client';
import VideoSpaceParticipantsPage from './VideoSpaceParticipantsPage';
import { jwtDecode } from 'jwt-decode';
import * as React from 'react';
import { overrideControlButtons } from '../utils/utils';
import ConfirmDialog from '../components/ConfirmDialog';
import CustomVideoConference from '../customLivekit/CustomVideoConference';

const UsersIcon = require('../assets/Users.png');

export interface VideoSpaceParticipant {
  name: string;
  identity: string;
  sid: string;
  isSpeaker: boolean;
}

export function VideoSpacePage() {
  const [room] = useState(new Room());
  const [showUsersBlock, setShowUsersBlock] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const tokenT = queryParameters.get('token') || undefined;
  const [participants, setParticipants] = useState<VideoSpaceParticipant[]>([]);
  const [isOrganizer, setIsOrganizer] = useState<boolean>(false);
  const [isSpeaker, setIsSpeaker] = useState<boolean>(false);
  const [showLeaveDialog, setShowLeaveDialog] = useState<boolean>(false);

  //const serverUrl = 'wss://emg-testapp-kc3hdr8p.livekit.cloud';
  const serverUrl = (window as any)._env_?.REACT_APP_LIVEKIT_SERVER_URL;
  //console.log(serverUrl);

  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  useEffect(() => {
    if (tokenT) {
      setIsOrganizer(decodeJwt(tokenT));
    }
  }, [tokenT]);

  useEffect(() => {
    if (isOrganizer) {
      overrideControlButtons();
    }
  }, [isOrganizer]);

  room.on(RoomEvent.ParticipantConnected, participant => {
    console.log(' - RoomEvent.ParticipantConnected', participant);
    getCallParticipants();
    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('participantConnected');
    }
  });

  room.on(RoomEvent.ParticipantPermissionsChanged, participant => {
    console.log(' - RoomEvent.ParticipantPermissionsChanged', participant);
    getCallParticipants();
  });

  room.on(RoomEvent.ParticipantDisconnected, participant => {
    console.log(' - RoomEvent.ParticipantDisconnected', participant);
    getCallParticipants();
  });

  room.on(RoomEvent.DataReceived, (payload: Uint8Array, participant: RemoteParticipant | undefined) => {
    const strData = decoder.decode(payload);
    const data = JSON.parse(strData);
    console.log('RoomEvent.DataReceived', data, participant);
    if (data?.closeRoom === true) {
      room.disconnect().then();
    }
  });

  const onDisconnected = () => {
    console.log('onDisconnected');
    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('disconnect');
    }
  };

  const onConnected = async () => {
    console.log('onConnected');
    getCallParticipants();
    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('connected');
    }
  };

  const decodeJwt = (token: string) => {
    if (!token) {
      console.error('empty token!', token);
      return false;
    }
    try {
      const jwt = jwtDecode(token.toString()) as { metadata: undefined };
      if (jwt && jwt?.metadata) {
        const metadataObj = JSON.parse(jwt.metadata);
        if (metadataObj?.isOrganizer) {
          return metadataObj.isOrganizer;
        }
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const getCallParticipants = () => {
    if (room.remoteParticipants.size || room.localParticipant) {
      const participantsArray: VideoSpaceParticipant[] = [];
      const localParticipant = room?.localParticipant;

      if (room?.remoteParticipants) {
        for (const [key, value] of room?.remoteParticipants) {
          participantsArray.push({
            name: value.name!,
            identity: value.identity,
            sid: value.sid,
            isSpeaker: value.permissions?.canPublish || false,
          });
        }
      }
      if (localParticipant) {
        participantsArray.push({
          name: localParticipant.name!,
          identity: localParticipant.identity,
          sid: localParticipant.sid,
          isSpeaker: localParticipant.permissions?.canPublish || false,
        });
        setIsSpeaker(localParticipant.permissions?.canPublish || false);
      }
      console.log('participants', participantsArray);
      setParticipants([...participantsArray]);
    }
  };

  const changeParticipantRole = (participant: VideoSpaceParticipant, role: 'speaker' | 'listener') => {
    if (!!(window as any).ReactNativeWebView) {
      console.log('changeParticipantRole', participant.name, role);
      const postMessageData = JSON.stringify({
        role: role,
        name: participant.name,
        matrixId: participant.identity,
      });
      (window as any).ReactNativeWebView.postMessage('changeUserRole' + postMessageData);
    }
  };

  const showUsers = () => {
    setShowUsersBlock(!showUsersBlock);
  };

  const onLeave = async (endVideoSpace: boolean) => {
    setShowLeaveDialog(false);

    if (endVideoSpace) {
      const strData = JSON.stringify({ closeRoom: true, ts: Date.now() });
      const data = encoder.encode(strData);
      await room.localParticipant.publishData(data, { reliable: true });
      if (!!(window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage('closeVideoSpace');
      }
    }
    room.disconnect(true).then();
  };

  return (
    <div id="VideoSpacePage">
      <LiveKitRoom
        room={room}
        data-lk-theme="VideoSpacePage-default"
        token={tokenT}
        serverUrl={serverUrl}
        connect={true}
        video={false}
        audio={true}
        onDisconnected={onDisconnected}
        onConnected={onConnected}
      >
        <div className="lk-wrapper">
          <div className={'lk-wrapper-left-content' + (showUsersBlock ? ' displayedParticipantsPage' : '')}>
            <CustomVideoConference isSpeaker={isSpeaker} />
            {isOrganizer && (
              <div id="lk-control-bar-buttons-override" className="lk-control-bar-custom hidden">
                <button className="lk-disconnect-button" onClick={() => setShowLeaveDialog(true)}>
                  <LeaveIcon /> Leave
                </button>
              </div>
            )}
            <div className="lk-control-bar-custom">
              {room.localParticipant.permissions && room.localParticipant.permissions?.canPublish !== true && (
                <MediaDeviceMenu kind={'audiooutput'} onActiveDeviceChange={console.log}>
                  <img
                    className="lk-button-icon-custom"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABE0lEQVR4nO2VMU4CURCGXwKFhQXEtVZvIIUGC02EI20CVGhC4gWggUMQtdFC4wG4AGcAtKHTxI+8zZ84soTA7qPjr+b/ZzL/25d5s87tERLAvYlHQA+4DNYcwHCLV+Asd3MPo1WBDjBNEjDL9DWY5tbA5I+AJ6W/gNPMzT1M7hmoKy4Aw6QAPjI39zB5j1+gKV4CJtJvVzXcCO6vvgl8S76R1hYf5DbwAGLJ7+Ln4mMXyCCSPBc/sDyEwfGSwWFog1jym3gl2BUBLeBHk3Qt7U5l/ZRBjjFtiJfNq06P6aYm7v9DqykuAo92okKuikhmHp/AyVYGa5bdFfCgJYeu58LtaF2/ZDr5KpOlH04316n38FgAeMYDlOowlMcAAAAASUVORK5CYII="
                    alt="Users"
                  />
                  Speaker
                </MediaDeviceMenu>
              )}
              <button className="lk-button" onClick={showUsers} style={{ marginLeft: 10 }}>
                <img className="lk-button-icon-custom" src={UsersIcon} alt={'Users'} />
                Participants
              </button>
            </div>
          </div>
          {showUsersBlock && (
            <div className="lk-wrapper-right-content">
              <VideoSpaceParticipantsPage
                participants={participants}
                isOrganizer={isOrganizer}
                room={room.name}
                onClose={() => setShowUsersBlock(false)}
                changeParticipantRole={changeParticipantRole}
              />
            </div>
          )}
          {showLeaveDialog ? (
            <ConfirmDialog
              dialogType={'confirm'}
              title={'Do you want to disable this videospace?'}
              acceptLabel={'Yes'}
              rejectLabel={'Only Disconnect'}
              onAccept={() => {
                onLeave(true).then();
              }}
              onReject={() => onLeave(false).then()}
            />
          ) : null}
        </div>
      </LiveKitRoom>
    </div>
  );
}
export default VideoSpacePage;
