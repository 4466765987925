import '@livekit/components-styles';
import './LivekiPage.scss';

import * as React from 'react';
import { LiveKitRoom } from '@livekit/components-react';
import { useRef, useState } from 'react';
import { RemoteParticipant, Room, RoomEvent, Track } from 'livekit-client';
import { jwtDecode } from 'jwt-decode';
import { ParticipantsPage } from './ParticipantsPage';
import toast, { Toaster } from 'react-hot-toast';
import DummyLocalParticipant from '../customLivekit/DummyLocalParticipant';
import { redirectToUrl } from '../utils/utils';
import CustomVideoConference from '../customLivekit/CustomVideoConference';

const UsersIcon = require('../assets/Users.png');

export function LivekitPage() {
  const [room] = useState(new Room());
  const [showParticipantsPage, setShowParticipantsPage] = useState(false);
  const [enabledParticipantsAdministration, setEnabledParticipantsAdministration] = useState(false);
  // hiddenParticipant - if is hidden participant (from public url)
  const [hiddenParticipant, setHiddenParticipant] = useState(true);
  // hiddenParticipantIsHidden - if hidden participant is hidden from other participants
  const [hiddenParticipantIsHidden, setHiddenParticipantIsHidden] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const tokenT = queryParameters.get('token') || undefined;
  const accessToken = queryParameters.get('at') || undefined;
  const lastDataReceivedTs = useRef<number>(Date.now());

  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  // const serverUrl = 'wss://emg-testapp-kc3hdr8p.livekit.cloud';
  const serverUrl = (window as any)._env_?.REACT_APP_LIVEKIT_SERVER_URL;
  console.log(serverUrl);

  room.on(RoomEvent.ParticipantConnected, participant => {
    console.log(' - RoomEvent.ParticipantConnected', participant);
    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('participantConnected');
    }
  });

  room.on(RoomEvent.DataReceived, (payload: Uint8Array, participant: RemoteParticipant | undefined) => {
    const strData = decoder.decode(payload);
    const data = JSON.parse(strData);
    console.log('RoomEvent.DataReceived', data, participant);
    if (data?.joinedHiddenParticipant === true && !hiddenParticipant) {
      if (lastDataReceivedTs.current < data.ts) {
        toast(t => (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              showUsers();
              toast.dismiss(t.id);
            }}
          >
            Someone is waiting to be accepted into the room..
          </span>
        ));
        lastDataReceivedTs.current = data.ts;
      }
    }
  });

  room.on(RoomEvent.ParticipantPermissionsChanged, (prevPermissions, participant) => {
    console.log(' - RoomEvent.ParticipantPermissionsChanged', prevPermissions, participant);
    setHiddenParticipantIsHidden(participant.permissions?.hidden === true);
  });

  const onDisconnected = () => {
    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('disconnect');
    }
    if (hiddenParticipant) {
      redirectToUrl('/end/' + room.name);
    }
  };

  const onConnected = async () => {
    const hiddenParticipantValue = room.localParticipant.permissions?.hidden === true;
    setHiddenParticipant(hiddenParticipantValue);
    setHiddenParticipantIsHidden(hiddenParticipantValue);
    // todo
    if (
      !hiddenParticipantValue &&
      (room?.localParticipant?.identity.includes('obhliadka.emeldi.com') ||
        room?.localParticipant?.identity.includes('superapp.emgroups.io'))
    ) {
      setEnabledParticipantsAdministration(true);
    } else if (hiddenParticipant) {
      const strData = JSON.stringify({ joinedHiddenParticipant: true, ts: Date.now() });
      const data = encoder.encode(strData);
      await room.localParticipant.publishData(data, { reliable: true });
    }

    if (!!(window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage('connected');
    }
    // const cameraEnabled = true;
    // if (cameraEnabled) {
    //   await room.localParticipant.setCameraEnabled(cameraEnabled);
    // }
  };

  const receiveMoneyAction = () => {
    if (!!(window as any).ReactNativeWebView) {
      const postMessageData = getCallParticipantsStringData();
      (window as any).ReactNativeWebView.postMessage(
        'receiveMoneyAction' + (postMessageData ? '_' + postMessageData : '')
      );
    }
  };

  const sendMoneyAction = () => {
    if (!!(window as any).ReactNativeWebView) {
      const postMessageData = getCallParticipantsStringData();
      (window as any).ReactNativeWebView.postMessage(
        'sendMoneyAction' + (postMessageData ? '_' + postMessageData : '')
      );
    }
  };

  const getCallParticipants = () => {
    if (room?.remoteParticipants) {
      const participants = [];
      for (const [key, value] of room?.remoteParticipants) {
        participants.push({ name: value.name, identity: value.identity });
      }
      return participants;
    }
    return [];
  };

  const getCallParticipantsStringData = () => {
    const participants = getCallParticipants();
    if (participants?.length) {
      return JSON.stringify(participants);
    }
    return '';
  };

  const isVideoCall = (token: string) => {
    if (!token) {
      console.error('getCallType empty token!', token);
      return false;
    }
    try {
      const jwt = jwtDecode(token.toString()) as { metadata: undefined };
      if (jwt && jwt?.metadata) {
        const metadataObj = JSON.parse(jwt.metadata);
        if (metadataObj?.callType) {
          return metadataObj.callType.toUpperCase() === 'VIDEO';
        }
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const showUsers = () => {
    setShowParticipantsPage(!showParticipantsPage);
  };

  return (
    <div id="CallPage">
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{ position: 'absolute' }}
        toastOptions={{ duration: 10000, style: { textAlign: 'center', maxWidth: '80%' } }}
      />
      <LiveKitRoom
        room={room}
        data-lk-theme="CallPage-default"
        token={tokenT}
        serverUrl={serverUrl}
        connect={true}
        video={false}
        audio={true}
        onDisconnected={onDisconnected}
        onConnected={onConnected}
      >
        <div className="lk-wrapper">
          <div
            className={
              'lk-wrapper-left-content' +
              (showParticipantsPage ? ' displayedParticipantsPage' : '') +
              (hiddenParticipant ? ' hiddenParticipant' : '')
            }
          >
            {hiddenParticipant && hiddenParticipantIsHidden ? (
              <DummyLocalParticipant room={room} />
            ) : (
              <>
                <CustomVideoConference />
                <div className="lk-control-bar-custom">
                  <button className="lk-button" onClick={receiveMoneyAction}>
                    <svg
                      className="lk-button-icon-custom"
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="70.000000pt"
                      height="98.000000pt"
                      viewBox="0 0 70.000000 98.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
                        fill="#FFFFFF"
                        stroke="none"
                      >
                        <path d="M394 966 c-16 -12 -20 -29 -22 -110 l-4 -95 -36 -3 c-62 -5 -55 -33 38 -159 45 -60 89 -113 98 -118 30 -17 53 3 138 117 98 133 103 155 37 160 l-43 3 0 93 c0 119 -6 126 -111 126 -49 0 -82 -5 -95 -14z" />
                        <path d="M160 658 c0 -18 -11 -27 -52 -43 -69 -27 -102 -72 -95 -133 6 -54 36 -87 99 -110 l48 -17 0 -67 c0 -38 -3 -68 -6 -68 -18 0 -43 24 -52 50 -10 27 -15 30 -56 30 -52 0 -54 -5 -27 -66 21 -46 60 -78 108 -88 25 -6 32 -13 35 -34 4 -39 38 -43 38 -5 0 26 5 30 44 41 162 43 166 224 6 277 l-50 17 0 54 c0 63 20 73 48 24 15 -26 23 -30 60 -30 47 0 52 11 27 60 -18 35 -59 65 -102 75 -26 5 -33 12 -33 31 0 17 -6 24 -20 24 -14 0 -20 -7 -20 -22z m0 -158 c0 -54 -1 -55 -31 -40 -34 19 -20 90 18 90 9 0 13 -16 13 -50z m88 -176 c42 -29 17 -104 -35 -104 -9 0 -13 18 -13 60 0 65 8 72 48 44z" />
                      </g>
                    </svg>
                    Request money
                  </button>
                  <button className="lk-button" onClick={sendMoneyAction} style={{ marginLeft: 10 }}>
                    <svg
                      className="lk-button-icon-custom"
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="70.000000pt"
                      height="98.000000pt"
                      viewBox="0 0 70.000000 98.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
                        fill="#FFFFFF"
                        stroke="none"
                      >
                        <path d="M459 967 c-32 -25 -169 -222 -169 -243 0 -23 17 -34 56 -34 24 0 24 -2 24 -85 0 -79 2 -88 26 -112 23 -23 31 -25 98 -21 102 5 106 10 106 124 l0 94 33 0 c36 0 57 15 57 39 0 9 -40 68 -88 131 -87 116 -110 133 -143 107z" />
                        <path d="M160 658 c0 -18 -11 -27 -52 -43 -69 -27 -102 -72 -95 -133 6 -54 36 -87 99 -110 l48 -17 0 -67 c0 -38 -3 -68 -6 -68 -18 0 -43 24 -52 50 -10 27 -15 30 -56 30 -52 0 -54 -5 -27 -66 21 -46 60 -78 108 -88 25 -6 32 -13 35 -34 4 -39 38 -43 38 -5 0 26 5 30 44 41 162 43 166 224 6 277 l-50 17 0 54 c0 63 20 73 48 24 15 -26 23 -30 60 -30 47 0 52 11 27 60 -18 35 -59 65 -102 75 -26 5 -33 12 -33 31 0 17 -6 24 -20 24 -14 0 -20 -7 -20 -22z m0 -158 c0 -54 -1 -55 -31 -40 -34 19 -20 90 18 90 9 0 13 -16 13 -50z m88 -176 c42 -29 17 -104 -35 -104 -9 0 -13 18 -13 60 0 65 8 72 48 44z" />
                      </g>
                    </svg>
                    Send money
                  </button>
                  {enabledParticipantsAdministration && (
                    <button className="lk-button" onClick={showUsers} style={{ marginLeft: 10 }}>
                      <img className="lk-button-icon-custom" src={UsersIcon} alt={'Users'} />
                      Participants
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          {enabledParticipantsAdministration && showParticipantsPage && (
            <div className="lk-wrapper-right-content">
              <ParticipantsPage
                roomName={room.name}
                accessToken={accessToken}
                localParticipantIdentity={room.localParticipant.identity}
                onClose={() => setShowParticipantsPage(false)}
              />
            </div>
          )}
        </div>
      </LiveKitRoom>
    </div>
  );
}

export default LivekitPage;
