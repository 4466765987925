import './VideoSpaceParticipantsPage.scss';
import React, { useState } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import ConfirmDialog from '../components/ConfirmDialog';
import { VideoSpaceParticipant } from './VideoSpacePage';

const DotsIcon = require('../assets/Dots.png');

export interface VideoSpaceParticipantsPageProps {
  room: string;
  onClose: () => void;
  participants: VideoSpaceParticipant[];
  isOrganizer: boolean;
  changeParticipantRole: (participant: VideoSpaceParticipant, changeTo: 'speaker' | 'listener') => void;
}

export function VideoSpaceParticipantsPage(props: VideoSpaceParticipantsPageProps) {
  const [participantInfoDialogContent, setParticipantInfoDialogContent] = useState<React.ReactElement>();

  const showParticipantInfo = (participant: VideoSpaceParticipant) => {
    setParticipantInfoDialogContent(
      <div>
        <div>
          <b>Name</b>: {participant.name}
        </div>
        <div>
          <b>Identity</b>: {participant.identity}
        </div>
      </div>
    );
  };

  const printSpeakerParticipant = (participant: VideoSpaceParticipant) => {
    if (!participant) return;

    return (
      <div className="participants-block-row" key={participant.identity}>
        <div>{participant.name}</div>
        <Menu
          menuClassName="custom-menu"
          menuButton={<img className="participants-block-row-icon" src={DotsIcon} alt={'Dots'} />}
          transition
        >
          <MenuItem className="custom-menuitem" onClick={() => showParticipantInfo(participant)}>
            Info
          </MenuItem>
          {props.isOrganizer && (
            <MenuItem
              className="custom-menuitem"
              onClick={async () => props.changeParticipantRole(participant, 'listener')}
            >
              Make Listener
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  const printListenerParticipant = (participant: VideoSpaceParticipant) => {
    if (!participant) return;

    return (
      <div className="participants-block-row" key={participant.identity}>
        <div>{participant.name}</div>
        <Menu
          menuClassName="custom-menu"
          menuButton={<img className="participants-block-row-icon" src={DotsIcon} alt={'Dots'} />}
          transition
        >
          <MenuItem className="custom-menuitem" onClick={() => showParticipantInfo(participant)}>
            Info
          </MenuItem>
          {props.isOrganizer && (
            <MenuItem className="custom-menuitem" onClick={() => props.changeParticipantRole(participant, 'speaker')}>
              Make Speaker
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  return (
    <div id="VideoSpaceParticipantsPage">
      <div className="participants-header">
        <span>Participants</span>
        <button className="lk-close-button lk-button lk-chat-toggle" aria-pressed="true" data-lk-unread-msgs="0" onClick={() => props.onClose()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path fill="#FFF"
                  d="M4.99 3.99a1 1 0 0 0-.697 1.717L10.586 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L12 13.414l6.293 6.293a1 1 0 1 0 1.414-1.414L13.414 12l6.293-6.293a1 1 0 0 0-.727-1.717 1 1 0 0 0-.687.303L12 10.586 5.707 4.293a1 1 0 0 0-.717-.303z"></path>
          </svg>
        </button>
      </div>
      <div className="participants-list">
        <div className="participants-block">
          <div className="participants-block-title">Speakers</div>
          {props.participants.filter(i => i.isSpeaker).map(participant => printSpeakerParticipant(participant))}
        </div>

        <div className="participants-block">
          <div className="participants-block-title">Listeners</div>
          {props.participants.filter(i => !i.isSpeaker).map(participant => printListenerParticipant(participant))}
        </div>
      </div>
      {participantInfoDialogContent ? (
        <ConfirmDialog
          dialogType={'info'}
          title={'Participant info'}
          onAccept={() => setParticipantInfoDialogContent(undefined)}>
          {participantInfoDialogContent || <></>}
        </ConfirmDialog>
      ) : null}
    </div>
  );
}

export default VideoSpaceParticipantsPage;
