import './Loading.css';

function keyframes() {
  return {
    __html: "@keyframes spin { 0% { transform: rotate(0deg) } 100% { transform: rotate(360deg) }}"
  };
}

const Loading = ({size = '80px', stroke = '5px', color = '#f91f31'}) => {
  return (
    <div id='Loading'>
      <div id='Loading-inner' style={{
        width: size, height: size, border: stroke + ' solid transparent', borderTopColor: color}}/>
      <style dangerouslySetInnerHTML={keyframes()}/>
    </div>
  );
};

export default Loading;
