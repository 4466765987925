export const getEnvironment = (): 'PROD' | 'TEST' => {
  if((window as any)?.location?.host) {
    return (window as any)?.location?.host.includes('superapp.emgroups.io') ? 'PROD' : 'TEST';
  }
  return  'TEST';
};

export const overrideControlButtons = () => {
  const originalButtonsWrapper = document.getElementsByClassName('lk-control-bar');
  const customButtonsWrapper = document.getElementById('lk-control-bar-buttons-override');

  if (originalButtonsWrapper?.length && customButtonsWrapper) {
    const buttons = customButtonsWrapper.getElementsByTagName('button');
    for (const button of buttons) {
      const className = button.className;
      const originalButton = originalButtonsWrapper[0].getElementsByClassName(className);
      if (originalButton?.length) {
        originalButton[0].className += ' hidden';
        originalButtonsWrapper[0].append(button);
      }
    }
  }
}

export const redirectToUrl = (path: string) => {
  window.location.href = window.location.protocol + '//' + window.location.host + path;
};
