import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LivekitPage from './pages/LivekiPage';
import JoinRoomPage from './pages/JoinRoomPage';
import VideoSpacePage from './pages/VideoSpacePage';
import CallEndPage from './pages/CallEndPage';
import JoinVideoSpacePage from './pages/JoinVideoSpacePage';

export function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <LivekitPage />,
    },
    {
      path: '/:roomName',
      element: <JoinRoomPage />,
    },
    {
      path: '/end/:roomName',
      element: <CallEndPage />,
    },
    {
      path: '/vs',
      element: <VideoSpacePage />,
    },
    {
      path: '/vs/:roomName',
      element: <JoinVideoSpacePage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
