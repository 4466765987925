import React from "react";
import './ConfirmDialog.css';

export interface ConfirmDialogProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dialogType: 'info' | 'confirm',
  title?: string;
  acceptLabel?: string
  rejectLabel?: string
  onAccept: () => void;
  onReject?: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {

  const onReject = () => {
    if (props?.onReject) {
      props.onReject();
    }
  }

  return (
    <div id='ConfirmDialog'>
      <div className='dialog-wrapper'>
        {props.title ? <div className='dialog-title'>{props.title}</div> : null}
        {props.children ? (
          <div className='dialog-content'>
            <div>{props.children}</div>
          </div>
        ) : null}
        <div className='dialog-footer'>
          {props.dialogType === 'info' ? (
            <div>
              <button className='lk-disconnect-button info' onClick={() => props.onAccept()}>Ok</button>
            </div>
          ) : (
            <div>
              <button className='lk-disconnect-button danger' onClick={() => onReject()}>
                {props.rejectLabel ? props.rejectLabel : 'Cancel'}
              </button>
              <button className='lk-disconnect-button success' onClick={() => props.onAccept()}>
                {props.acceptLabel ? props.acceptLabel : 'Confirm'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConfirmDialog;
