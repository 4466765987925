import './CustomVideoConference.scss';
import {
  ControlBar,
  TrackRefContext,
  useTracks,
  RoomAudioRenderer,
  ConnectionStateToast,
  useCreateLayoutContext,
  LayoutContextProvider,
  Chat,
  usePinnedTracks,
  CarouselLayout,
  FocusLayout,
  FocusLayoutContainer,
} from '@livekit/components-react';
import { Track } from 'livekit-client';
import * as React from 'react';
import { isEqualTrackRef, WidgetState } from '@livekit/components-core';
import CustomGridLayout from './CustomGridLayout';
import CustomParticipantTile from './CustomParticipantTile';

export const CustomVideoConference = ({ isSpeaker }: any) => {
  const tracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ]);

  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: false,
    unreadMessages: 0,
  });
  const layoutContext = useCreateLayoutContext();

  const focusTrack = usePinnedTracks(layoutContext)?.[0];
  const carouselTracks = tracks.filter(track => !isEqualTrackRef(track, focusTrack));

  const widgetUpdate = (state: WidgetState) => {
    setWidgetState(state);
  };

  return (
    <>
      <div id="CustomVideoConference" className="lk-video-conference">
        <LayoutContextProvider
          value={layoutContext}
          // onPinChange={handleFocusStateChange}
          onWidgetChange={widgetUpdate}
        >
          <div className="lk-video-conference-inner">
            {!focusTrack ? (
              <div className="lk-grid-layout-wrapper">
                <CustomGridLayout tracks={tracks}>
                  <TrackRefContext.Consumer>
                    {trackRef => trackRef && <CustomParticipantTile trackRef={trackRef} />}
                  </TrackRefContext.Consumer>
                </CustomGridLayout>
              </div>
            ) : (
              <div className="lk-focus-layout-wrapper">
                <FocusLayoutContainer>
                  <CarouselLayout tracks={carouselTracks}>
                    <TrackRefContext.Consumer>
                      {trackRef => trackRef && <CustomParticipantTile trackRef={trackRef} />}
                    </TrackRefContext.Consumer>
                  </CarouselLayout>
                  {focusTrack && <FocusLayout trackRef={focusTrack} />}
                </FocusLayoutContainer>
              </div>
            )}
            <ControlBar controls={{ chat: true, screenShare: isSpeaker || false }} />
          </div>
          <Chat style={{ display: widgetState.showChat ? 'flex' : 'none' }} />
        </LayoutContextProvider>
      </div>
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </>
  );
};

export default CustomVideoConference;
