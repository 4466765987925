import './CustomVideoConference.scss';
import * as React from 'react';
import {
  AudioTrack,
  ConnectionQualityIndicator,
  FocusToggle,
  isTrackReference,
  ParticipantName,
  TrackMutedIndicator,
  VideoTrack
} from "@livekit/components-react";
import {Track} from "livekit-client";
import {getEnvironment} from "../utils/utils";

export interface CustomParticipantTileProps {
  trackRef: any;
}

export const CustomParticipantTile = (props: CustomParticipantTileProps) => {
  const trackRef = props.trackRef;

  const MEDIA_URL = {
    test: 'https://matrix.obhliadka.emeldi.com/_matrix/media/r0/thumbnail/matrix.obhliadka.emeldi.com/',
    prod: 'https://matrix.superapp.emgroups.io/_matrix/media/r0/thumbnail/matrix.superapp.emgroups.io/',
  };
  const MEDIA_URL_FULL = getEnvironment() === 'PROD' ? MEDIA_URL.prod : MEDIA_URL.test;

  const getAvatarUrl = (metadata: string): string => {
    const mediaId = JSON.parse(metadata)?.matrixMediaId;
    if (mediaId) {
      return MEDIA_URL_FULL + mediaId + '?width=320&height=320&method=scale';
    }
    return '';
  };

  return (
    <div
      className="lk-participant-tile"
      style={{ position: 'relative' }}
      data-lk-source={trackRef.source}
    >
      {trackRef?.participant.isCameraEnabled || trackRef?.participant.isScreenShareEnabled ? (
        <>
          {isTrackReference(trackRef) &&
          (trackRef.publication?.kind === 'video' ||
            trackRef.source === Track.Source.Camera ||
            trackRef.source === Track.Source.ScreenShare) ? (
            <VideoTrack trackRef={trackRef} />
          ) : (
            isTrackReference(trackRef) && <AudioTrack trackRef={trackRef} />
          )}
        </>
      ) : (
        <>
          <div className="lk-participant-placeholder custom">
            {trackRef?.participant?.metadata && getAvatarUrl(trackRef.participant.metadata) ? (
              <div className="image-wrapper">
                <img
                  src={getAvatarUrl(trackRef.participant.metadata)}
                  alt="avatar"
                  style={{
                    height: '320px',
                    width: '320px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                />
              </div>
            ) : (
              <svg
                width="320"
                height="320"
                viewBox="0 0 320 320"
                preserveAspectRatio="xMidYMid meet"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M160 180C204.182 180 240 144.183 240 100C240 55.8172 204.182 20 160 20C115.817 20 79.9997 55.8172 79.9997 100C79.9997 144.183 115.817 180 160 180Z"
                  fill="white"
                  fillOpacity="0.25"
                ></path>
                <path
                  d="M97.6542 194.614C103.267 191.818 109.841 192.481 115.519 195.141C129.025 201.466 144.1 205 159.999 205C175.899 205 190.973 201.466 204.48 195.141C210.158 192.481 216.732 191.818 222.345 194.614C262.703 214.719 291.985 253.736 298.591 300.062C300.15 310.997 291.045 320 280 320H39.9997C28.954 320 19.8495 310.997 21.4087 300.062C28.014 253.736 57.2966 214.72 97.6542 194.614Z"
                  fill="white"
                  fillOpacity="0.25"
                ></path>
              </svg>
            )}
          </div>
          <div className="lk-participant-metadata">
            <div className="lk-participant-metadata-item">
              <TrackMutedIndicator
                trackRef={{
                  participant: trackRef.participant,
                  source: Track.Source.Microphone,
                }}
                show={'muted'}
              ></TrackMutedIndicator>
              <ParticipantName />
            </div>
            <ConnectionQualityIndicator className="lk-participant-metadata-item" />
          </div>
        </>
      )}
      <FocusToggle trackRef={trackRef} />
    </div>
  )
};

export default CustomParticipantTile;
