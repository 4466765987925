import * as React from "react";
import {
  GridLayoutProps,
  TrackLoop,
  useSwipe,
} from '@livekit/components-react';
import {mergeProps} from "./components/mergeProps";
import {PaginationControl} from "./components/PaginationControl";
import {PaginationIndicator} from "./components/PaginationIndicator";
import {useGridLayout} from "./components/useGridLayout";
import usePagination from "./components/usePagination";

export const CustomGridLayout = ({ tracks, ...props }: GridLayoutProps) => {
  const gridEl = React.createRef<HTMLDivElement>();

  const elementProps = React.useMemo(
    () => mergeProps(props, { className: 'lk-grid-layout' }),
    [props],
  );
  const { layout } = useGridLayout(gridEl, tracks.length);
  const maxTiles = layout.columns * layout.rows;
  const pagination = usePagination(maxTiles, tracks);

  useSwipe(gridEl, {
    onLeftSwipe: pagination.nextPage,
    onRightSwipe: pagination.prevPage,
  });

  return (
    <div ref={gridEl} data-lk-pagination={pagination.totalPageCount > 1} {...elementProps}>
      <TrackLoop tracks={pagination.tracks}>{props.children}</TrackLoop>
      {tracks.length > maxTiles && (
        <>
          <PaginationIndicator
            totalPageCount={pagination.totalPageCount}
            currentPage={pagination.currentPage}
          />
          <PaginationControl pagesContainer={gridEl} {...pagination} />
        </>
      )}
    </div>
  );
};

export default CustomGridLayout;
