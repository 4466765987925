import axios from 'axios';
import { getEnvironment } from '../utils/utils';

export interface LiveKitPublicTokenRequest {
  username: string;
  identity: string;
  roomName: string;
}

export interface UpdateParticipantRequest {
  username: string;
  identity: string;
  roomName: string;
  permissions: {
    canPublish: boolean;
    canSubscribe: boolean;
    hidden: boolean;
  };
}

export interface LivekitParticipant {
  username: string;
  identity: string;
  sid: string;
  hidden: boolean;
}

const VIDEO_MANAGEMENT_URL = {
  test: 'https://api-gateway.obhliadka.emeldi.com/video-management',
  prod: 'https://api-gateway.superapp.emgroups.io/video-management',
};

const getVideoManagementUrl = () => {
  if ((window as any)?.location?.host) {
    return getEnvironment() === 'PROD' ? VIDEO_MANAGEMENT_URL.prod : VIDEO_MANAGEMENT_URL.test;
  }
  return VIDEO_MANAGEMENT_URL.test;
};

export const generatePublicLiveKitToken = (
  username: string,
  identity: string,
  roomName: string
): Promise<string | null> => {
  return new Promise(response => {
    const request: LiveKitPublicTokenRequest = { username, identity, roomName };

    axios({
      method: 'post',
      url: getVideoManagementUrl() + '/public/video/token',
      data: request,
    })
      .then((responseData: any) => {
        if (responseData && responseData.data) {
          response(responseData.data);
        } else {
          response(null);
        }
      })
      .catch(error => {
        console.log(error);
        response(null);
      });
  });
};

export const checkActiveVideoSpace = (videoSpaceId: string): Promise<boolean> => {
  return new Promise(response => {
    axios({
      method: 'get',
      url: getVideoManagementUrl() + `/public/video-space/${videoSpaceId}`,
    })
      .then((responseData: any) => {
        if (responseData && responseData.data) {
          response(responseData.data.active);
        } else {
          response(false);
        }
      })
      .catch(error => {
        console.log(error);
        response(false);
      });
  });
};

export const generatePublicLiveKitVideoSpaceToken = (
  username: string,
  identity: string,
  roomName: string
): Promise<string | null> => {
  return new Promise(response => {
    const request: LiveKitPublicTokenRequest = { username, identity, roomName };

    axios({
      method: 'post',
      url: getVideoManagementUrl() + '/public/video-space/token',
      data: request,
    })
      .then((responseData: any) => {
        if (responseData && responseData.data) {
          response(responseData.data);
        } else {
          response(null);
        }
      })
      .catch(error => {
        console.log(error);
        response(null);
      });
  });
};

export const listParticipants = (roomName: string, accessToken: string | undefined): Promise<LivekitParticipant[]> => {
  return new Promise(response => {
    if (!roomName || !accessToken) {
      response([]);
    }
    axios({
      method: 'get',
      url: getVideoManagementUrl() + '/api/video/participants/' + roomName,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((responseData: any) => {
        console.log(responseData);
        const livekitParticipants: LivekitParticipant[] = [];
        if (responseData?.data?.length) {
          responseData?.data.forEach((participant: any) => {
            livekitParticipants.push({
              identity: participant.identity_,
              username: participant.name_,
              hidden: participant.permission_.hidden_,
              sid: participant.sid_,
            });
          });
        }
        response(livekitParticipants);
      })
      .catch(error => {
        console.log(error);
        response([]);
      });
  });
};

export const updateParticipant = (
  request: UpdateParticipantRequest,
  accessToken: string | undefined
): Promise<boolean> => {
  return new Promise(response => {
    if (!request || !accessToken) {
      response(false);
    }
    axios({
      method: 'post',
      url: getVideoManagementUrl() + '/api/video/participant',
      data: request,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then((responseData: any) => {
        if (responseData && responseData.data) {
          response(responseData.data);
        } else {
          response(false);
        }
      })
      .catch(error => {
        console.log(error);
        response(false);
      });
  });
};

export const removeParticipant = (
  roomName: string,
  identity: string,
  username: string,
  accessToken: string | undefined
): Promise<boolean> => {
  return new Promise(response => {
    if (!roomName || !identity || !accessToken) {
      response(false);
    }
    updateParticipant(
      {
        username: username,
        identity: identity,
        roomName: roomName,
        permissions: {
          canPublish: false,
          canSubscribe: false,
          hidden: true,
        },
      },
      accessToken
    ).then((success: boolean) => {
      if (success) {
        axios({
          method: 'delete',
          url: getVideoManagementUrl() + '/api/video/participant/' + roomName + '/' + identity,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
          },
        })
          .then((responseData: any) => {
            console.log(responseData);
            response(responseData.data);
          })
          .catch(error => {
            console.log(error);
            response(false);
          });
      } else {
        response(false);
      }
    });
  });
};
