import './LivekiPage.scss';
import './CallEndPage.scss';
import {getEnvironment, redirectToUrl} from "../utils/utils";
import React from "react";
import {useParams} from "react-router-dom";

export function CallEndPage() {
  const { roomName } = useParams();
  const environment = getEnvironment();

  const WEB_URL = {
    prod: 'https://web.superapp.emgroups.io/',
    test: 'https://superapp.obhliadka.emeldi.com/'
  };

  const webUrl = environment === "PROD" ? WEB_URL.prod : WEB_URL.test;

  const rejoin = () => {
    redirectToUrl('/' + roomName);
  };

  return (
    <div id="CallEndPage">
      <div className='ec-wrapper'>
        <div className='ec-title'>You left the meeting</div>
        <div className='ec-button-group'>
          <button className='lk-button ec-button-rejoin' onClick={rejoin}>Rejoin</button>
        </div>
        <div className="ec-text">
          Experience the EMG Superapp in your device, or explore the web version today.
        </div>
        <div className='ec-button-group'>
          <a className='ep-apple-badge' href="https://apps.apple.com/us/app/emg-superapp/id6444855984?itsct=apps_box_badge&amp;itscg=30200" >
            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1681516800" alt="Download on the App Store"/>
          </a>
          <a className='ep-google-badge' href='https://play.google.com/store/apps/details?id=com.emg.superapp&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
          </a>
        </div>
        <div className='ec-button-group'>
          <a className='ep-web-badge' href={webUrl}>Web app</a>
        </div>
      </div>
    </div>
  );
}

export default CallEndPage;
